export default defineI18nConfig(() => ({
    // 👇 Use Vue I18n's Composition API
    legacy: false,
    fallbackLocale: 'de',
    // locale: 'de',
    // messages: {
    //     de: {
    //         welcome: 'Formular Seite --- DEUTSCH ---',
    //         txtImageComp: 'Text in der Image Komponente [TBS]',
    //         txtInfoPage: 'Text auf der Info Seite [TBS]',
    //         index: {
    //             index1: 'Text Index 1 [TBS] Deutsch',
    //             index2: 'Text Index 2 [TBS] Deutsch',
    //         },
    //         page: {
    //             login: {
    //                 title: 'Einloggen Seite --- DEUTSCH ---'
    //             }
    //         },
    //         link: {
    //             privacy: 'Datenschutzerklärung'
    //         }
    //     },
    //     en: {
    //         welcome: 'Form page --- ENGLISH ---',
    //         txtImageComp: 'Text in the image component [TBS]',
    //         txtInfoPage: 'Text on the info page [TBS]',
    //         index: {
    //             index1: 'Text Index 1 [TBS] English',
    //             index2: 'Text Index 2 [TBS] English',
    //         },
    //         page: {
    //             login: {
    //                 title: 'Login Page --- ENGLISH ---'
    //             }
    //         },
    //         link: {
    //             privacy: 'Data privacy'
    //         }
    //     }
    // }
}))